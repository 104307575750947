import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import DefaultLayout from './layouts/DefaultLayout'
import router from './router'
import webSocketService from './services/webSocketService'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VTooltip, { VPopover } from 'v-tooltip'
import { formatDate } from './filters/formatDate'
import VueToastify from "vue-toastify";
import InteractiveLayout from './layouts/InteractiveLayout'
import store from "./store"

const options = {
  defaultPlacement: 'top-start',
  defaultTrigger: 'click',
}
Vue.use(VTooltip, options)
Vue.use(VPopover, options)

Vue.component('default-layout', DefaultLayout)
Vue.component('interactive-layout', InteractiveLayout)

Vue.config.productionTip = false
Vue.config.devtools = false

Vue.use(webSocketService, {})
Vue.use(VueAxios, axios)
Vue.use(VueToastify);

Vue.filter('formatDate', formatDate)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
