var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('div',{staticClass:"menu-bar pointer dropdown dropdown-6",on:{"click":_vm.setShowMenuList,"mouseover":function($event){!_vm.isMobile ? _vm.setShowMenuList : ''}}},[_c('div',{staticClass:"image-menu"})]),(_vm.closeEffect || _vm.showMenuList)?_c('div',{class:[
      'menu-list-wrapper dropdown_menu dropdown_menu--animated dropdown_menu-6',
      { close: _vm.closeEffect, open: _vm.showMenuList }
    ]},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideShowMenuList),expression:"hideShowMenuList"}],staticClass:"menu-list"},[_c('div',{staticClass:"bold"},[_vm._v("kern systems")]),_c('div',{staticClass:"nav-block"},[_c('div',{staticClass:"bold"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_vm._v(" Olli ")]),_c('ul',{staticClass:"olli"},[_c('li',{staticClass:"normal pointer",on:{"click":_vm.hideShowMenuList}},[_c('router-link',{staticClass:"normal",attrs:{"to":{
                  name: 'Interactive',
                  query: {
                    type: 'interactive-demo'
                  }
                }}},[_vm._v(" interactive demo ")])],1),_c('li',{staticClass:"normal pointer",on:{"click":_vm.hideShowMenuList}},[_c('router-link',{staticClass:"normal",attrs:{"to":{ name: 'InputPage', query: { type: 'encode' } }}},[_vm._v(" encode ")])],1),_c('li',{staticClass:"normal pointer",on:{"click":_vm.hideShowMenuList}},[_c('router-link',{staticClass:"normal",attrs:{"to":{ name: 'InputPage', query: { type: 'decode' } }}},[_vm._v(" decode ")])],1),_c('li',{staticClass:"normal pointer",on:{"click":_vm.hideShowMenuList}},[_c('router-link',{staticClass:"normal",attrs:{"to":{
                  name: 'JobLookUp',
                  query: { type: 'find-previous-job' }
                }}},[_vm._v(" find previous job ")])],1),_c('li',{staticClass:"normal pointer",on:{"click":_vm.hideShowMenuList}},[_c('router-link',{staticClass:"normal",attrs:{"to":{ name: 'Feedback', query: { type: 'leave-feedback' } }}},[_vm._v(" Leave feedback ")])],1)])],1)])])]):_vm._e(),_c('div',{staticClass:"left"},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"first pointer",on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("Olli")]),_c('span',{staticClass:"separator"},[_vm._v("/")]),_c('span',{staticClass:"second"},[_vm._v(_vm._s(_vm.type))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }